@layer components {
  .dialog {
    scroll-behavior: smooth;
    background-color: var(--modal-background-color, none);
    overflow-y: var(--modal-overflow, auto);
    min-width: var(--modal-min-width, auto);
    max-width: var(--modal-max-width, none);
    max-height: var(--modal-max-height, none);
    padding: var(--modal-padding, 0);
    color: var(--color-gray-500);
    overscroll-behavior: contain;
    width: var(--modal-width, 100%);
    height: var(--modal-height, 100%);
    margin: var(--modal-margin, 0 auto);
    flex-direction: var(--modal-flex-direction, row);
    justify-content: var(--modal-justify-content, center);
    align-items: var(--modal-align-items, normal);
    transition: overlay 300ms allow-discrete, transform 300ms;
  }

  .dialog[open] {
    display: var(--modal-display, flex);
  }

  .panel {
    display: var(--modal-panel-display, block);
    background: var(--modal-panel-background, var(--color-white));
    box-shadow: var(--modal-panel-shadow);
    border-radius: var(--modal-panel-border-radius);
    height: var(--modal-panel-height, 100%);
    min-height: var(--modal-panel-min-height, auto);
    max-height: var(--modal-panel-max-height, none);
    width: var(--modal-panel-width, 100%);
    flex-direction: var(--modal-panel-flex-direction, row);
    justify-content: var(--modal-panel-justify-content, center);
    margin: var(--modal-panel-margin, 0);
    align-items: var(--modal-panel-align-items, normal);
    min-width: var(--modal-panel-min-width, auto);
    max-width: var(--modal-panel-max-width, none);
    position: var(--modal-panel-position, relative);
    overflow: var(--modal-panel-overflow, hidden);
    padding: var(--modal-panel-padding, 0);
  }

  .dialog[open],
  .dialog[open] .panel {
    opacity: 1;
  }

  /* Safari < 17.3 does not support custom properties on ::backdrop */
  .dialog::backdrop {
    background: #000;
    transition: opacity 300ms allow-discrete, transform 300ms;
    opacity: 0;
  }

  .dialog[open]::backdrop {
    opacity: 0.3;
    pointer-events: all;
  }

  .dialog:not([open]) {
    display: none !important; /* stylelint-disable-line declaration-no-important */
  }

  .grow {
    transform: scale(0.95) translate3d(0, 10%, 0);
  }

  .grow[open] {
    transform: scale(1) translate3d(0, 0, 0);
  }

  .bottomSlide {
    transform: translateY(100%);
  }

  .bottomSlide[open] {
    opacity: 1;
    transform: translateY(0);
  }

  .leftSlide {
    transition: transform 300ms ease-in-out,
      display 300ms ease-in-out allow-discrete,
      overlay 300ms ease-in-out allow-discrete;
    transform: translateX(-100%);
  }

  .leftSlide[open] {
    transform: translateX(0);
  }

  @starting-style {
    .dialog[open],
    .dialog[open]::backdrop {
      opacity: 0;
    }

    .grow[open] {
      transform: scale(0.95) translate3d(0, 10%, 0);
    }

    .bottomSlide[open] {
      transform: translateY(100%);
    }

    .leftSlide[open] {
      transform: translateX(-100%);
    }
  }
}
